var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.pessoaJuridica)?_c('div',{staticClass:"col-8 offset-2"},[_c('h5',{staticClass:"font-weight-light"},[_c('b',[_vm._v(" Olá "+_vm._s(_vm.auth.getUserInfo().nomeFantasia)+", agora você é um parceiro Bike Registrada.")])]),_vm._m(0),_vm._m(1)]):_c('div',{staticClass:"col-8 offset-2"},[_c('h5',{staticClass:"font-weight-light"},[_c('b',[_vm._v(" Olá "+_vm._s(_vm.auth.getUserInfo().nomeFantasia)+", agora você é um parceiro Bike Registrada.")]),_c('br')]),_vm._m(2),_c('div',{staticClass:"mt-5"},[_vm._m(3),_vm._m(4),_c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Compartilhe seu "),_c('router-link',{attrs:{"to":"/dashboard/link-parceiro"}},[_vm._v(" link ")]),_vm._v(" de indicação com sua galera em suas redes sociais, assim você pode vender e ganhar mais $$$ ")],1),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"font-weight-light"},[_c('b',[_vm._v(" Segue abaixo os benefícios para sua loja. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Vender seguro de bikes por assinatura para seus clientes de forma rápida, simples e barata. ")]),_c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Mostre sua loja para todo Brasil de forma rápida e online. Anuncie suas bikes para +400mil ciclistas em todo Brasil (basta clicar "),_c('a',{attrs:{"href":"https://classificados.bikeregistrada.com.br","target":"_blank"}},[_vm._v("aqui")]),_vm._v(") ")]),_c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Selo de segurança Bike Registrada. Mostre para seu cliente que você se preocupa com a segurança dele e é contra o comércio de bikes roubadas no Brasil. Fale com um de nossos representantes pelo telefone (61) 3522-4521 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"font-weight-light"},[_c('b',[_vm._v(" Veja abaixo os benefícios que temos para você! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v(" Ganhe dinheiro vendendo seguro para seus amigos do pedal. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Registre suas bikes e mostre que você se preocupa com a segurança no pedal ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Use nosso "),_c('a',{attrs:{"href":"https://classificados.bikeregistrada.com.br/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("classificados e anuncie e compre bikes usadas")]),_vm._v(" classificados e anuncie e compre bikes usadas ou novas com procedência ")])
}]

export { render, staticRenderFns }